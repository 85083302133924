var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PixIntegradorExibirDetalhesPixDialog',{ref:"PixIntegradorExibirDetalhesPixDialog"}),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("NFS-e")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},[_c('v-radio',{attrs:{"label":"Por Recebimento","value":"R"}}),_c('v-radio',{attrs:{"label":"Por Emissão","value":"E"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"propTitle":"Recebimento"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loadingGerandoXml},on:{"click":function($event){return _vm.handleGerarXmlPeriodo()}}},[_vm._v(" Gerar Xml por periodo ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Totalizadores por categoria")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{ref:"totalizadorCategoria",attrs:{"headers":_vm.porCategoriaHeaders,"items":_vm.porCategoriaItems,"footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.financeiro_categoria_nome",fn:function(ref){
var value = ref.value;
return [(value != 'null')?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(" Não conciliado ")])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor))+" ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',[_c('b',[_vm._v("Totais")])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm._f("formatPriceValue")(_vm.sumField(_vm.porCategoriaItems, "valor"))))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.sumField(_vm.porCategoriaItems, "quantidade")))])])])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Totalizadores por origem")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{ref:"totalizadorCategoria",attrs:{"headers":_vm.porOrigemHeaders,"items":_vm.porOrigemItems,"show-select":"","item-key":"origem","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor))+" ")]}},{key:"item.origem",fn:function(ref){
var value = ref.value;
return [(parseInt(value))?_c('span',[_vm._v(" "+_vm._s(_vm.getFormaDePagamento(parseInt(value)))+" ")]):_c('span',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',[_c('b',[_vm._v("Totais")])]),_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm._f("formatPriceValue")(_vm.sumField(_vm.porCategoriaItems, "valor"))))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.sumField(_vm.porCategoriaItems, "quantidade")))])])])]}}],null,true),model:{value:(_vm.selectedOrigemItems),callback:function ($$v) {_vm.selectedOrigemItems=$$v},expression:"selectedOrigemItems"}})],1)],1)],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Totalizadores por status")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{ref:"totalizadorCategoria",attrs:{"headers":_vm.porOrigemHeaders,"items":_vm.porStatusNFItems,"footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor))+" ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',[_c('b',[_vm._v("Totais")])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm._f("formatPriceValue")(_vm.sumField(_vm.porStatusNFItems, "valor"))))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.sumField(_vm.porStatusNFItems, "quantidade")))])])])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterItems,"loading":_vm.loading,"search":_vm.search,"dense":"","loading-text":"Carregando...","multi-sort":"","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.end_to_end_id",fn:function(ref){
var item = ref.item;
return [(item.end_to_end_id || item.txid)?_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){return _vm.showPixDetails(item)}}},[_vm._v(" "+_vm._s(item.end_to_end_id)+" ")]):_vm._e()]}},{key:"item.horario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(item.horario))+" ")]}},{key:"item.plugnotas_data_emissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.plugnotas_data_emissao))+" ")]}},{key:"item.origem",fn:function(ref){
var item = ref.item;
return [(item.end_to_end_id)?_c('span',[(Number(item.origem))?_c('span',[_vm._v(" "+_vm._s(_vm.getFormaDePagamento(item.origem))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.origem)+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.getFormaDePagamento(item.origem))+" ")])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor))+" ")]}},{key:"item.entidade_documento",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatCpfCnpj")(value))+" ")]}},{key:"item.financeiro_parcela",fn:function(ref){
var item = ref.item;
return [(item.financeiro_parcela_parcela_numero)?_c('span',[_vm._v(" "+_vm._s(item.financeiro_parcela_parcela_numero)+"/"+_vm._s(item.financeiro_numero_parcelas)+" ")]):_vm._e()]}},{key:"item.entidade_nome",fn:function(ref){
var item = ref.item;
return [(item.entidade_id)?_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.entidade_nome)),"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}}):_vm._e()]}},{key:"item.financeiro_parcela_id",fn:function(ref){
var item = ref.item;
return [(item.financeiro_parcela_id)?_c('span',[_vm._v(" "+_vm._s(item.financeiro_parcela_id)+" ")]):_c('v-chip',{attrs:{"color":"error","small":""}},[_vm._v(" Não conciliado ")])]}},{key:"item.plugnotas_id",fn:function(ref){
var item = ref.item;
return [(_vm.allowNF(item.financeiro_categoria_id))?_c('PlugnotasConsultaEmiteButton',{key:item.plugnotas_id,attrs:{"propPlugnotasId":item.plugnotas_id,"propPlugnotasStatus":item.plugnotas_status,"propFinanceiroParcelaMovimentacaoId":item.financeiro_parcela_movimentacao_id,"propTipoDocumento":1}}):_vm._e()]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(h,idx){return _c('td',{key:idx},[(h.aggregate_function == 'sum')?_c('b',[_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(_vm.sumField(_vm.items, h.value)))+" ")]):_vm._e()])}),0)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { pixStore, userToolStore, mainStore, plugnotasNfseServicoStore, plugnotasConfiguracaoStore, plugnotasStore } from '@/store';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import PixIntegradorExibirDetalhesPixDialog from '@/components/PixIntegradorExibirDetalhesPixDialog.vue';
import PlugnotasConsultaEmiteButton from '@/components/PlugnotasConsultaEmiteButton.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import { downloadZip } from '@/utils';
import { differenceInDays } from 'date-fns';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.radioGroup = 'R';
        this.loading = false;
        this.loadingGerandoXml = false;
        this.search = '';
        this.configuracaoIntegracao = [];
        this.selectPeriodDate = null;
        this.nfseServicosCadastradosItems = null;
        this.plugnotasConfiguracao = null;
        this.selectedOrigemItems = [];
        this.porCategoriaHeaders = [
            { text: 'Categoria', value: 'financeiro_categoria_nome' },
            { text: 'Valor', value: 'valor' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.porOrigemHeaders = [
            { text: 'Origem', value: 'origem' },
            { text: 'Valor', value: 'valor' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
    }
    get headers() {
        const array = [
            { text: 'Banco', value: 'banco_nome' },
            { text: 'Recebido em', value: 'horario' },
            { text: 'Emitida em', value: 'plugnotas_data_emissao' },
            { text: 'Valor Recebido', value: 'valor', aggregate_function: 'sum' },
            { text: 'Origem', value: 'origem' },
            { text: 'PIX - E2E', value: 'end_to_end_id' },
            { text: 'PIX - TXID', value: 'txid' },
            { text: 'PIX - Info. Pagador', value: 'info_pagador' },
            { text: 'Parcela - ID', value: 'financeiro_parcela_id' },
            { text: 'Entidade - Nome', value: 'entidade_nome' },
            { text: 'Entidade - Documento', value: 'entidade_documento' },
            { text: 'Financeiro - Parcela', value: 'financeiro_parcela' },
            { text: 'Financeiro - Categoria', value: 'financeiro_categoria_nome' },
            { text: 'Financeiro - Observação', value: 'financeiro_observacao' },
        ];
        // @ts-ignore
        if (this.plugnotasConfiguracao &&
            this.plugnotasConfiguracao.aceite_user_id) {
            array.push({ text: 'NF', value: 'plugnotas_id' });
        }
        return array;
    }
    get porOrigemItems() {
        if (this.items) {
            return _(this.items)
                .groupBy('origem')
                .map((item, id) => {
                const qntdObj = _.countBy(item, 'origem');
                const quantidade = Object.values(qntdObj)[0];
                return {
                    origem: id,
                    valor: _.sumBy(item, 'valor'),
                    quantidade,
                };
            })
                .value();
        }
    }
    get porStatusNFItems() {
        if (this.items) {
            return _(this.items)
                .groupBy('plugnotas_status')
                .map((item, id) => {
                const qntdObj = _.countBy(item, 'plugnotas_status');
                const quantidade = Object.values(qntdObj)[0];
                if (id == 'null') {
                    id = 'NÃO EMITIDA';
                }
                return {
                    origem: id,
                    valor: _.sumBy(item, 'valor'),
                    quantidade,
                };
            })
                .value();
        }
    }
    get porCategoriaItems() {
        if (this.items) {
            return _(this.items)
                .groupBy('financeiro_categoria_nome')
                .map((item, id) => {
                const qntdObj = _.countBy(item, 'financeiro_categoria_nome');
                const quantidade = Object.values(qntdObj)[0];
                return {
                    financeiro_categoria_nome: id,
                    valor: _.sumBy(item, 'valor'),
                    quantidade,
                };
            })
                .value();
        }
    }
    get items() {
        return pixStore.nfses;
    }
    get filterItems() {
        let items = pixStore.nfses;
        if (!this.selectedOrigemItems.length) {
            return [];
        }
        const origensSelecionadas = this.selectedOrigemItems.map((item) => {
            if (Number(item.origem)) {
                return Number(item.origem);
            }
            if (item.origem === 'null') {
                return null;
            }
            return item.origem;
        });
        items = items.filter((i) => origensSelecionadas.includes(i.origem));
        return items;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`NFSe`]) {
            return userToolStore.userToolsIndexed[`NFSe`][0].allow_delete;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    allowNF(financeiroCategoriaId) {
        return _.some(this.nfseServicosCadastradosItems, (i) => i.financeiro_categoria_id == financeiroCategoriaId);
    }
    async showPixDetails(item) {
        // @ts-ignore
        this.$refs.PixIntegradorExibirDetalhesPixDialog.show(item.company_configuracao_integracao_bancaria_id, item.txid, item.end_to_end_id);
    }
    getCompanyId() {
        return (parseInt(this.$route.params.id, 0) || mainStore.userProfile.company.id);
    }
    async buscar() {
        this.loading = true;
        await pixStore.pixListarRecebimentos({
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
            curso: true,
            material_didatico: false,
            conciliacao_status_id: 2,
            tipo_pesquisa: this.radioGroup
        });
        if (!this.nfseServicosCadastradosItems) {
            this.nfseServicosCadastradosItems = await plugnotasNfseServicoStore.getPlugnotasNfseServicosByCompanyId(this.getCompanyId());
        }
        this.selectedOrigemItems = this.porOrigemItems;
        this.loading = false;
    }
    async handleGerarXmlPeriodo() {
        const differenceDays = differenceInDays(new Date(this.selectPeriodDate[1]), new Date(this.selectPeriodDate[0]));
        if (differenceDays > 31) {
            this.$swal({
                title: 'Erro ao gerar nesse intervalo',
                text: 'Somente é possível gerar as notas no periodo máximo de 31 dias',
                icon: 'error',
                showCancelButton: false
            });
            return;
        }
        this.loadingGerandoXml = true;
        const responseData = await plugnotasStore.gerandoArquivoXmlPeriodo({ tipoNota: 'nfse', dataInicial: this.selectPeriodDate[0], dataFinal: this.selectPeriodDate[1] });
        this.loadingGerandoXml = false;
        const nomeArquivoGerado = `${this.selectPeriodDate[0]}_${this.selectPeriodDate[1]}_NFSE`;
        if (responseData) {
            downloadZip(nomeArquivoGerado, responseData);
        }
    }
    async mounted() {
        this.plugnotasConfiguracao =
            await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
    }
};
List = __decorate([
    Component({
        components: {
            ClickableCard,
            PeriodSelectorComponent,
            PixIntegradorExibirDetalhesPixDialog,
            PlugnotasConsultaEmiteButton,
            OpenNewTabComponent
        }
    })
], List);
export default List;
